import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ThemeContext from '../../context/ThemeContext'; // Import ThemeContext for dark mode toggle
import Logo from '../../assets/Logo.png';
import './Hover.css';

const Hover = () => {
  const { t, i18n } = useTranslation(); // useTranslation hook to get the translation function and i18n instance
  const { theme, toggleTheme } = useContext(ThemeContext); // Access theme and toggleTheme

  // Function to change language
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change language using i18next
  };

  return (
    <section id="hero" className="hero section"> {/* Section for the hero part */}
      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
            <h1 data-aos="fade-up">{t('hero_title')}</h1> {/* Translated hero title */}
            <p data-aos="fade-up" data-aos-delay="100">{t('hero_description')}</p> {/* Translated hero description */}
            {/* <a href="https://foundrybyte.com" className="btn-get-started" target="_blank" rel="noopener noreferrer">
<span style={{ color: '#007FFF', fontWeight: 'bold'  }}>{t('hero_button')}</span> <i className="bi bi-arrow-right" style={{ marginLeft: '8px' }}></i>
</a> */}
          </div>
          <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-out">
  <a href="https://foundrybyte.com" target="_blank" rel="noopener noreferrer">
    <img
      src={Logo}
      className="img-fluid animated custom-image"
      alt="Logo"
      style={{
        width: '100%',
        maxHeight: '300px',
        objectFit: 'contain'
      }}
    />
  </a>
</div>
        </div>

        {/* Language switch buttons */}
        <div className="language-switch mt-3">
          <button
            onClick={() => changeLanguage('en')}
            className="btn btn-primary me-2 rounded-pill"
          >
            En
          </button>
          <button
            onClick={() => changeLanguage('fr')}
            className="btn btn-primary rounded-pill"
          >
            Fr
          </button>
        </div>

        <br />

        {/* Toggle button for dark mode */}
        <div className="dark-mode-toggle">
          <button onClick={toggleTheme} className="btn btn-outline-secondary rounded-pill">
            {theme === 'light' ? 'Dark' : 'Light'} Mode
          </button>
        </div>
      </div>
    </section>
  );
};

export default Hover;
